@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.fixedTopbar {
  .topbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    height: 57px;
    flex-shrink: 0;
    background: rgba(222, 209, 249, 1);
    padding: 0;
  }
  .HorizontalTopbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  &.contentArea {
    padding-top: $topbarHeight;
  }
}
.ddWidth {
  width: 350px;
  // width: fit-content !important;
}

.nav_link {
  left: 101px;
  top: 20px;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 12px !important;
  font-family: "Poppins" !important;
  text-transform: capitalize !important;
  color: #393838 !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.nav_link span {
  margin-top: 1px;
  white-space: nowrap;
}

.nav_link:focus {
  color: #393838;
}

.nav_link:hover {
  color: #393838;
}

.activeLink {
  background: rgb(201 177 232 / 62%);
  height: 57px;
  color: #393838;
}

.activeSubLink {
  background: rgb(201 177 232 / 62%);
  color: #393838;
}

.nav_item {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
}

.nav_item:hover {
  background: rgb(201 177 232 / 62%);
}

.parent_nav_item:hover {
  background: rgb(201 177 232 / 62%);
}

.parent_nav_item button {
  height: 57px;
}

.parent_nav_item .nav_item:hover {
  background: none;
}

.parent_nav_item {
  display: flex;
  align-items: center;
  // padding: 0 10px;
  cursor: pointer;
}

.parent_nav_item button:active {
  border: none;
}

.sidebarIcon img {
  width: 13.001px;
  height: 13.001px;
  flex-shrink: 0;
}

.mega-dropdown {
  position: static;
  .dropdown-menu {
    padding: 30px;
    width: 60%;
    max-height: 480px;
    top: 11px !important;
    overflow: auto;
  }
}

.form-switch .form-check-input {
  position: relative;
  background: #f64e60;
  height: 18px;

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }
  &:checked {
    background-color: #1de9b6;
    &:after {
      left: 20px;
      // right: 3px;
    }
  }
}
.top-bar-toggle .form-check {
  // min-height: 29px;
}
.top-bar-toggle {
  @media screen and (max-width: 575px) {
    // border: 0 !important;
    // padding-right: 8px !important;
    padding: 1px 5px !important;
  }
  // .cont {
  //   p {
  //     font-size: 12px;
  //     font-weight: 900;
  //     &.small {
  //       font-size: 10px;
  //       font-weight: 300;
  //     }
  //   }
  // }
  .form-switch {
    @media screen and (max-width: 575px) {
      min-height: auto;
      .form-check-input {
        height: 18px !important;
        width: 30px !important;
        &::after {
          width: 12px !important;
          height: 12px !important;
          left: 13px !important;
        }
      }
    }
    .form-check-input {
      // position: relative;
      // background: #b3c6ff;
      height: 23px;
      width: 42px;
      // border-color: #b3c6ff;
      position: relative;
      background: transparent;
      border: 2px solid #b3c6ff;
      margin-left: auto;
      margin-top: 0.1em;
      &::after {
        width: 17px;
        height: 17px;
      }
      &:checked {
        // background-color: #1de9b6;
        // border-color: #1de9b6;
        background-color: #12c03d;
        border-color: #12c03d;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .fixedTopbar {
    .topbar {
      width: 100%;
      left: auto;
    }
  }
  .isMiniSidebar .fixedTopbar .topbar {
    width: 100%;
    left: auto;
  }
}
@include media-breakpoint-down(lg) {
  .ddWidth {
    width: 100vw;
    max-width: 400px;
  }
}

@media screen and (max-width: 575px) {
  .topbar .navbar-brand {
    margin-right: 0;
  }
  .topbar .container-fluid {
    justify-content: space-around !important;
  }
  .topbar {
    button.d-sm-block.d-lg-none.btn.btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        height: 20px;
      }
    }
    .dropdown button.btn.btn-primary {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
@media screen and (max-width: 400px) {
  .top-bar-toggle .cont p {
    font-size: 12px;
  }
  .top-bar-toggle .cont p.small {
    font-size: 8px;
  }
  .top-bar-toggle {
    padding: 1px 4px !important;
    margin-right: 5px !important;
  }
  .topbar .mega-dropdown {
    margin: 0 !important;
  }
  .topbar .dropdown button.btn.btn-primary {
    padding-left: 4px;
    padding-right: 4px;
  }
  .topbar button.d-sm-block.d-lg-none.btn.btn-primary {
    padding-left: 5px;
    padding-right: 5px;
  }
  .topbar .container-fluid {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.topbar .container-fluid {
  padding: 0;
  height: 57px;
}

.nav_item button:active {
  border: none;
}

.nav_item:focus-visible {
  outline: none;
}

.sub_link {
  padding: 10px;
  display: block;
  padding: 0.7rem 1rem !important;
  display: block !important;
  width: 100%;
}
.sub_item {
  padding: 0rem;
  border-radius: 4px;
  margin-bottom: 2px;
}

.sub_item:hover {
  background-color: #ececec !important;
}

.nav_item_width {
  // width: 115px;
  justify-content: center;
}
