@import "variables";
@import "simplebar/dist/simplebar.min.css";
@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "./layout/sidebar";
@import "./layout/container";
@import "./layout/customizer";
@import "./layout/horizontal";
@import "./layout/topbar";
@import "./layout/rtl/rtl";
