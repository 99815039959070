.pageWrapper {
  min-height: 100vh;
}

.pagination-position {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contentArea {
  flex-grow: 1;
}

.boxContainer {
  // max-width: 1400px;
}

.card {
  margin-bottom: 30px;
  box-shadow: $box-shadow;
}

.dropdown-menu {
  box-shadow: $box-shadow;
}

.cursor-pointer {
  cursor: pointer;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;

  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }

  &.md-box {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px;

    @media only screen and (max-width: 575px) {
      // width: 30px;
      // height: 30px;
      // line-height: 26px;
      // font-size: 15px;
    }
  }
}

.button-group .btn {
  margin: 3px;
}

.btn-white {
  border: 1px solid $border-color;
}

.simplebar-scrollbar:before {
  background: #a0a0a0;
}

.apexcharts-toolbar {
  z-index: 0 !important;
  display: none !important;
}

.leftRightBox {
  overflow: hidden;
}

.op-3 {
  opacity: 0.3;
}

.v-right-sidepoup {
  height: calc(100vh - 104px);
  display: grid;
  align-content: space-between;


  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // background: transparent;
    background: rgb(223, 223, 223);
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }

  .v-create-ticket-height {
    max-height: calc(100vh - 126px);
    overflow: auto;
    margin-right: -15px;
    padding-right: 15px;
    margin-bottom: 10px;
  }
}

.MuiFormControl-root {
  &.small-input {
    label {
      &.MuiFormLabel-root {
        // top: -2px;
        line-height: 14px;
        color: rgb(0 0 0 / 79%);

        &.MuiFormLabel-filled,
        &.Mui-focused {
          top: 3px;
        }
      }
    }

    .MuiInputBase-input {
      padding: 9.5px 14px;
    }
  }
}

.MuiFormControl-root {
  &.v-textarea-small {
    label {
      &.MuiFormLabel-root {
        line-height: 16px;
        color: rgb(0 0 0 / 79%);
      }
    }
  }
}

.v-select_width {
  .MuiFormControl-root {
    label {
      &.MuiFormLabel-root {
        color: rgb(0 0 0 / 79%);
      }
    }
  }
}

/*************Left right two column ***************/
.leftPart {
  width: 300px;
  min-height: calc(100vh - 200px);
  position: relative;
  transition: 0.1s ease-in;

  @media (max-width: 575px) {
    width: calc(100% - 45px);
    left: calc(-100% + 45px) !important;

    &.showLeftPart {
      width: calc(100% - 45px);
      left: 0 !important;
    }
  }

  .openCloseBtn {
    position: absolute;
    right: -42px;
    top: 13px;
  }
}

.threeColumn {
  .leftPart {

    @media (min-width: 576px) {
      width: 250px;
    }
  }
}

.rightPart {
  flex-grow: 1;
  min-height: calc(100vh - 200px);
  transition: 0.1s ease-in;
}

.closeRbtn {
  right: 15px;
  top: 15px;
  z-index: 2;
}

.contentOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
}

@include media-breakpoint-down(lg) {
  .leftPart {
    position: absolute;
    left: -300px;
    z-index: 1;
    box-shadow: $box-shadow;

    &.showLeftPart {
      left: 0;
    }

    button.btn-danger.v-side-setup {
      position: absolute;
      right: -43px;
      top: 12px;
      z-index: 9;
    }
  }

  .threeColumn {
    .rightPart {
      position: absolute;
      right: -100%;
      top: 0;
      width: 100%;
      z-index: 1;

      &.showRightPart {
        right: 0;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .threeColumn {
    .leftPart {
      left: -250px;
      position: absolute;
      z-index: 1;
      box-shadow: $box-shadow;

      &.showLeftPart {
        left: 0;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .threeColumn {
    .leftPart {
      width: 250px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .middlePart {
    width: 300px;
    border-right: 1px solid $border-color;
    min-height: calc(100vh - 200px);
    position: relative;
    transition: 0.1s ease-in;
  }
}

.loginBox {
  height: 100vh;

  .loginContainer {
    max-width: 390px;
  }
}

.table> :not(:first-child) {
  border-width: 0;
}

.text-dark-white {
  color: #fff !important;
}

// for editor
.rdw-embedded-modal {
  left: unset !important;
  right: 5px !important;
  height: auto !important;
}

.rdw-embedded-modal-header-option {
  width: 100% !important;
}