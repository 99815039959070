@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.sidebarArea {
  flex-shrink: 0;
  width: 100%;

  .sidebarBox {
    height: 100%;
    width: 100%;
    position: relative;
    transition: 0.1s ease-in;
    z-index: 2;
    background: #f9f4ff !important;
  }
  .fixedSidebar {
    position: fixed;
  }
  .navCaption {
    font-size: 0.775rem;
    padding: 5px 15px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nav-item.activeParent > .nav-link {
    opacity: 1;
  }
  .activeParent {
    border-radius: 8px;
    background: #e3d0fc;
  }
  .nav-link {
    // opacity: 0.65;
    display: flex;
    padding: 12px 5px 12px 12px;
    align-items: center;
    white-space: nowrap;
    color: #393838;
    font-family: "Poppins";
    // font-size: 13px;
    font-style: normal;
    // font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    svg {
      width: 21px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .lightText {
    color: $white;
  }
  .activeLink .nav-link {
    opacity: 1;
  }
  .subMenu {
    padding: 10px 0px;
    list-style: none;
    transition: 0.1s ease-in;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
    .nav-link {
      padding: 9px 15px;
    }
    .sidebarIcon {
      visibility: hidden;
    }
  }
}

.activeMobileLink {
  background: rgb(201 177 232 / 62%);
  // height: 57px;
  color: #393838;
  border-radius: 8px;
}
.sidebarOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}
.horizontalNav {
  position: relative;
  z-index: 1;

  .nav-item {
    position: relative;
  }
  .nav-link {
    color: inherit;
    opacity: 0.65;
    display: flex;
    padding: 15px 12px;
    transition: 0.1s ease-in;
    svg {
      width: 21px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .firstDD {
    .nav-link {
      padding: 9px 15px;
      &:hover {
        padding-left: 20px;
      }
    }
    svg {
      width: 18px;
    }
  }
  .lightText {
    color: $white;
  }
  .activeLink .nav-link {
    opacity: 1;
  }
}
@include media-breakpoint-up(lg) {
  .horizontalNav {
    &.fixedSidebar {
      position: fixed;
      width: 100%;
    }

    .firstDD {
      position: absolute;
      min-width: 250px;
      padding: 10px;
      display: none;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
    }
    .nav-item:hover .firstDD {
      display: block;
    }
  }
  .HsidebarFixed {
    padding-top: 64px;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    z-index: 9;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
  .horizontalNav {
    width: $sidebarWidth;
    margin-left: -$sidebarWidth;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    transition: 0.1s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
    .firstDD {
      display: none;
      &.showfirstDD {
        display: block;
      }
    }
    .nav-link,
    .nav-item {
      width: 100%;
    }
    .nav-item:focus .firstDD {
      display: block;
    }
  }
}
@include media-breakpoint-up(lg) {
  .isMiniSidebar {
    .sidebarArea {
      width: $miniSidebarWidth;
      .sidebarBox {
        width: $miniSidebarWidth;
        &:hover {
          width: $sidebarWidth;
          .hide-mini {
            display: block;
          }
        }
        .hide-mini {
          display: none;
        }
      }
    }
  }
}

.ReactModal__Content {
  &.modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;

    .modal-content {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;

      .modal-body {
        padding: 1rem;
      }

      .modal-header {
        padding: 1rem;
        border-bottom: 1px solid #dee2e6;
      }

      .modal-footer {
        padding: 0.75rem;
        border-top: 1px solid #dee2e6;

        * {
          margin: 0.25rem;
        }
      }
    }
  }
}
